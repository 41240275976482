import React from 'react';
import {Col, Container, Row, Accordion} from "react-bootstrap";

function Question(props) {
    return (
        <div className={props.color ? 'services_question bg_color' : 'services_question '}>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={4} xxl={4} className="question_title">
                        <h2>FAQs</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="services_faqs">

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What are the key considerations before choosing offshore software
                                    development services?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Here are the core benefits of offshore development:</p>
                                    <ul>
                                        <li><strong>It's cheaper. </strong>In Ukraine, you'll pay $25-50/h, while in
                                            the
                                            US the cost goes up to $150-250/h.
                                        </li>
                                        <li><strong>No training costs.</strong> You don't waste time and money on
                                            training employees – it's the job of your offshore partner.
                                        </li>
                                        <li><strong>Skillset.</strong> Most offshore companies are focused on
                                            certain
                                            industries or technologies, so it'll be easy to find engineers with
                                            specific
                                            skills.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="1">
                                <Accordion.Header> What
                                    are
                                    the
                                    pros
                                    of
                                    offshore
                                    software
                                    development
                                    services ?
                                </Accordion.Header>
                                <Accordion.Body>
                                    < p> Here
                                        are
                                        the
                                        core
                                        benefits
                                        of
                                        offshore
                                        development:</
                                        p>
                                    <ul>
                                        <li>< strong> It
                                            's cheaper. </strong>In Ukraine, you'
                                            ll
                                            pay
                                            $25 - 50 / h,
                                            while in
                                            the
                                            US
                                            the
                                            cost
                                            goes
                                            up
                                            to
                                            $150 - 250 / h.
                                        </li>
                                        <
                                            li>< strong> No
                                            training
                                            costs.
                                        </strong>
                                            You
                                            don
                                            't waste time and money on
                                            training
                                            employees – it
                                            's the job of your offshore partner.
                                        </li>
                                        <li><strong>Skillset.</strong> Most offshore companies are focused on
                                            certain
                                            industries or technologies, so it'll be easy to find engineers with
                                            specific
                                            skills.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What are the key focus areas of offshore software development
                                    services?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Here are the core benefits of offshore development:</p>
                                    <ul>
                                        <li><strong>It's cheaper. </strong>In Ukraine, you'll pay $25-50/h, while in
                                            the
                                            US the cost goes up to $150-250/h.
                                        </li>
                                        <li><strong>No training costs.</strong> You don't waste time and money on
                                            training employees – it's the job of your offshore partner.
                                        </li>
                                        <li><strong>Skillset.</strong> Most offshore companies are focused on
                                            certain
                                            industries or technologies, so it'll be easy to find engineers with
                                            specific
                                            skills.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header> Why are
                                    offshore
                                    software
                                    development
                                    services
                                    becoming
                                    popular ?
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Here
                                        are
                                        the
                                        core
                                        benefits
                                        of
                                        offshore
                                        development:
                                    </p>
                                    <ul>
                                        <li>< strong> It
                                            's cheaper. </strong>In Ukraine, you'
                                            ll
                                            pay
                                            $25 - 50 / h,
                                            while in
                                            the
                                            US
                                            the
                                            cost
                                            goes
                                            up
                                            to
                                            $150 - 250 / h.
                                        </li>
                                        <li>< strong> No
                                            training
                                            costs.
                                        </strong>
                                            You
                                            don
                                            't waste time and money on
                                            training
                                            employees – it
                                            's the job of your offshore partner.
                                        </li>
                                        <li><strong>Skillset.</strong> Most offshore companies are focused on
                                            certain
                                            industries or technologies, so it'll be easy to find engineers with
                                            specific
                                            skills.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>What will be the custom software development
                                    service?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Here are the core benefits of offshore development:</p>
                                    <ul>
                                        <li><strong>It's cheaper. </strong>In Ukraine, you'll pay $25-50/h,
                                            while in the
                                            US the cost goes up to $150-250/h.
                                        </li>
                                        <li><strong>No training costs.</strong> You don't waste time and money
                                            on
                                            training employees – it's the job of your offshore partner.
                                        </li>
                                        <li><strong>Skillset.</strong> Most offshore companies are focused on
                                            certain
                                            industries or technologies, so it'll be easy to find engineers with
                                            specific
                                            skills.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Question;